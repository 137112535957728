import React from "react"
import Layout from "hoc/Layout"
import Hr from "components/Hr/Hr"
import { graphql, useStaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { options } from "../constants/richTextOptions"

const RulesPage = () => {
  const SITE_NAME = "Regulamin"

  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    SITE_NAME,
  ]

  const data = useStaticQuery(graphql`
    query TermsAndRulesQuery {
      allContentfulStronaRegulaminu(limit: 1) {
        nodes {
          content {
            raw
          }
        }
      }
    }
  `)

  const text = data.allContentfulStronaRegulaminu.nodes[0].content
  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle={SITE_NAME}>
      <Hr mb={4} mt={6} />
      {renderRichText(text, options)}
    </Layout>
  )
}

export default RulesPage
